import {massagesProductList} from "./MassagesData";
import {Card, Col, Row} from "reactstrap";
import React from "react";
import {holisticProductList} from "./HolisticData";
import {reflexologyProductList} from "./ReflexologyData";
import {hypnoseProductList} from "./HypnoseData";

export const formulesProductList = [
    {
        id: "zen_1",
        title: "Zen 1",
        subtitle: "Relaxation totale, énergie renouvelée.",
        image: require("assets/img/products/packages/zen1.jpg"),
        description: "Découvrez l'harmonie parfaite entre relaxation profonde et énergie revitalisante avec un Massage bien-être apaisant suivi d'une séance régénérante de Soin énergétique Lahochi, pour une expérience de bien-être holistique incomparable.",
        sessionsInfos: [
            {
                duration: 90,
                price: 120
            },
            {
                duration: 120,
                price: 160
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>La formule ZEN 1 associe un massage bien-être et un soin énergétique Lahochi</p>
                    <hr/>
                    <Row>
                        {[
                            ...massagesProductList.filter((product) => product.id === "massage_relaxant"),
                            ...holisticProductList.filter((product) => product.id === "lahochi")
                        ].map((product, i) => {
                            return (
                                <Col lg="6">
                                    <Card className="card-product odd card-plain">
                                        <div className="card-image">
                                            <img alt={product.title} className="img img-raised" src={product.image}/>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title mb-1">{product.title}</h4>
                                            <h6 className="card-category">{product.subtitle}</h6>
                                            <p className="card-description">{product.description}</p>
                                            <hr/>
                                        </div>
                                    </Card>
                                </Col>)
                        })
                        }
                    </Row>
                </>
        }
    },
    {
        id: "zen_2",
        title: "Zen 2",
        subtitle: "Équilibre profond, libération énergétique",
        image: require("assets/img/products/packages/zen2.jpg"),
        description: "Offrez à vos pieds une véritable cure de détente et d'équilibre avec une séance de réflexologie plantaire, suivie d'un massage métamorphique pour libérer les tensions et stimuler votre bien-être intérieur, un voyage revitalisant pour l'esprit et le corps.",
        sessionsInfos: [
            {
                duration: 90,
                price: 120
            },
            {
                duration: 120,
                price: 160
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>La formule ZEN 2 associe une séance réflexologie plantaire et un massage métamorphique</p>
                    <hr/>
                    <Row>
                        {[
                            ...reflexologyProductList.filter((product) => product.id === "reflexology_plantar"),
                            ...holisticProductList.filter((product) => product.id === "metamorphic")
                        ].map((product, i) => {
                            return (
                                <Col lg="6">
                                    <Card className="card-product odd card-plain">
                                        <div className="card-image">
                                            <img alt={product.title} className="img img-raised" src={product.image}/>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title mb-1">{product.title}</h4>
                                            <h6 className="card-category">{product.subtitle}</h6>
                                            <p className="card-description">{product.description}</p>
                                            <hr/>
                                        </div>
                                    </Card>
                                </Col>)
                        })
                        }
                    </Row>
                </>
        }
    },
    {
        id: "zen_3",
        title: "Zen 3",
        subtitle: "Sérénité, harmonie",
        image: require("assets/img/products/packages/zen3.jpg"),
        description: "Combinez l'extase du Bodhypnosis et soit un soin énergétique, soit un massage aux bols tibétains pour une expérience inoubliable.",
        sessionsInfos: [
            {
                duration: 90,
                price: 120
            },
            {
                duration: 120,
                price: 160
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>La formule ZEN 3 associe une séance Bodhypnosis et au choix: un soin énergétique ou un massage aux bols tibétains.</p>
                    <hr/>
                    <Row>
                        { hypnoseProductList.filter((product) => product.id === "hypnose_corporelle").map((product, i) => {
                            return (<Col lg="4">
                                <Card className="card-product odd card-plain">
                                    <div className="card-image">
                                        <img alt={product.title} className="img img-raised" src={product.image}/>
                                    </div>
                                    <div className="card-body">
                                        <h4 className="card-title mb-1">{product.title}</h4>
                                        <h6 className="card-category">{product.subtitle}</h6>
                                        <p className="card-description">{product.description}</p>
                                    </div>
                                </Card>
                            </Col>)
                        })}
                        <Col lg="1" className="align-self-center text-center">
                            <b><u>et</u></b>
                        </Col>
                        { holisticProductList.filter((product) => product.id === "energetic").map((product, i) => {
                            return (<Col lg="3">
                                <Card className="card-product odd card-plain">
                                    <div className="card-image">
                                        <img alt={product.title} className="img img-raised" src={product.image}/>
                                    </div>
                                    <div className="card-body">
                                        <h4 className="card-title mb-1">{product.title}</h4>
                                        <h6 className="card-category">{product.subtitle}</h6>
                                        <p className="card-description">{product.description}</p>
                                    </div>
                                </Card>
                            </Col>)
                        })}
                        <Col lg="1" className="align-self-center text-center">
                            <b><u>ou</u></b>
                        </Col>
                        {[
                            ...massagesProductList.filter((product) => product.id === "massage_bols_tibetains")
                        ].map((product, i) => {
                            return (
                                <Col lg="3">
                                    <Card className="card-product odd card-plain">
                                        <div className="card-image">
                                            <img alt={product.title} className="img img-raised" src={product.image}/>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title mb-1">{product.title}</h4>
                                            <h6 className="card-category">{product.subtitle}</h6>
                                            <p className="card-description">{product.description}</p>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </>
        }
    },
    {
        id: "detox_future_mom",
        title: "Détox future maman",
        subtitle: "Relaxation et Légèreté",
        image: require("assets/img/products/packages/detoxmaman.jpg"),
        description: "Ce soin allie massage prénatal et drainage lymphatique des jambes pour soulager tensions et lourdeurs, favorisant une sensation de légèreté et de détente pendant la grossesse.",
        sessionsInfos: [
            {
                duration: 90,
                price: 150
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>La séance est composée d'un massage prénatal apaisant et d'un drainage lymphatique ciblé sur les jambes</p>
                    <hr/>
                    <Row>
                        {[
                            ...massagesProductList.filter((product) => product.id === "massage_prenatal"),
                            ...massagesProductList.filter((product) => product.id === "massage_lymphatique"),
                        ].map((product, i) => {
                            return (
                                <Col lg="6">
                                    <Card className="card-product odd card-plain">
                                        <div className="card-image">
                                            <img alt={product.title} className="img img-raised" src={product.image}/>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title mb-1">{product.title}</h4>
                                            <h6 className="card-category">{product.subtitle}</h6>
                                            <p className="card-description">{product.description}</p>
                                            <hr/>
                                        </div>
                                    </Card>
                                </Col>)
                        })
                        }
                    </Row>
                </>
        }
    },
    {
        id: "zen_future_mom",
        title: "Zen future maman",
        subtitle: "Éveil et bien-être pour maman et bébé",
        image: require("assets/img/products/packages/zenmaman.jpg"),
        description: "Plongez dans un univers de douceur et de transformation avec un massage métamorphique apaisant, suivi d'une séance de massage prénatal conçue pour vous accompagner dans cette période spéciale de votre vie, offrant une relaxation profonde et un lien magique avec votre bébé à venir.",
        sessionsInfos: [
            {
                duration: 90,
                price: 140
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>La séance est composée d’un temps de massage métamorphique et d'un second temps de massage
                        prénatal.</p>
                    <hr/>
                    <Row>
                        {[
                            ...holisticProductList.filter((product) => product.id === "metamorphic"),
                            ...massagesProductList.filter((product) => product.id === "massage_prenatal"),
                        ].map((product, i) => {
                            return (
                                <Col lg="6">
                                    <Card className="card-product odd card-plain">
                                        <div className="card-image">
                                            <img alt={product.title} className="img img-raised" src={product.image}/>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title mb-1">{product.title}</h4>
                                            <h6 className="card-category">{product.subtitle}</h6>
                                            <p className="card-description">{product.description}</p>
                                            <hr/>
                                        </div>
                                    </Card>
                                </Col>)
                        })
                        }
                    </Row>
                </>
        }
    },
    {
        id: "zen_dos",
        title: "Zen dos",
        subtitle: "Relaxation, bien-être du dos.",
        image: require("assets/img/products/packages/zendos.jpg"),
        description: "Relaxez-vous en profondeur avec une séance unique de massage du dos combinée à un massage au bol tibétain, une expérience doublement apaisante pour soulager les tensions musculaires et équilibrer votre énergie corporelle.",
        sessionsInfos: [
            {
                duration: 60,
                price: 88
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>La séance est composée d’un temps de massage du dos ainsi que d'un massage aux bols tibétains sur
                        le dos.</p>
                    <hr/>
                    <Row>
                        {[
                            ...massagesProductList.filter((product) => product.id === "massage_relaxant").map(product => {
                                    const copiedProduct = Object.assign({}, product);
                                    copiedProduct.title = "Massage relaxant dos"
                                    return copiedProduct;
                                }
                            ),
                            ...massagesProductList.filter((product) => product.id === "massage_bols_tibetains").map(product => {
                                    const copiedProduct = Object.assign({}, product);
                                    copiedProduct.title = "Massage sonore aux bols tibétains dos"
                                    copiedProduct.image = require("assets/img/products/massages/massage_bol_tibetains_dos.jpg")
                                    return copiedProduct;
                                }
                            ),
                        ].map((product, i) => {
                            return (
                                <Col lg="6">
                                    <Card className="card-product odd card-plain">
                                        <div className="card-image">
                                            <img alt={product.title} className="img img-raised" src={product.image}/>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title mb-1">{product.title}</h4>
                                            <h6 className="card-category">{product.subtitle}</h6>
                                            <p className="card-description">{product.description}</p>
                                            <hr/>
                                        </div>
                                    </Card>
                                </Col>)
                        })
                        }
                    </Row>
                </>
        }
    },
    {
        id: "bulle_future_mom",
        title: "Bulle future maman",
        subtitle: "Bien-être maternel",
        image: require("assets/img/products/massages/massage_rebozzo.jpg"),
        description: "L'alliance du massage relaxant, du massage Rebozo et du massage prénatal vous offre une expérience complète.",
        sessionsInfos: [
            {
                duration: 120,
                price: 174
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>La formule combinant le massage relaxant, le massage Rebozo, et le massage prénatal offre une approche holistique, apportant un soutien spécifique à la grossesse tout en permettant une détente profonde.
                        <br/>Cette expérience complète favorise le soulagement des tensions physiques et émotionnelles, procurant un bien-être total.</p>
                    <hr/>
                    <Row>
                        {[
                            ...massagesProductList.filter((product) => product.id === "massage_relaxant"),
                            ...massagesProductList.filter((product) => product.id === "massage_rebozo"),
                            ...massagesProductList.filter((product) => product.id === "massage_prenatal"),
                        ].map((product, i) => {
                            return (
                                <Col lg="4">
                                    <Card className="card-product odd card-plain">
                                        <div className="card-image">
                                            <img alt={product.title} className="img img-raised" src={product.image}/>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title mb-1">{product.title}</h4>
                                            <h6 className="card-category">{product.subtitle}</h6>
                                            <p className="card-description">{product.description}</p>
                                            <hr/>
                                        </div>
                                    </Card>
                                </Col>)
                        })
                        }
                    </Row>
                </>
        }
    },
]
